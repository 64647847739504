@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.Toastify__toast-container {
  padding: 0;
}
.Toastify__toast {
  padding: 0;
}
.Toastify__toast-body {
  padding: 0;
}
.content {
  padding: 12px 24px;
}
.sticky-box {
  z-index: 2;
}
